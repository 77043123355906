.dummy {
  min-width: none;
}

.navbar-right li {
  margin-top: 12px;
  margin-right: 10px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

[hidden] {
  display: none !important;
}

.btn-close-modal {
  margin-left: auto;
  margin-bottom: 0px;
  border: none;
  background-color: transparent;
}

.mdi-close {
  font-size: 16px !important;
}

.margin-small {
  margin: 5px;
}

.row {
  margin-bottom: 10px;
  line-height: 30px;
}

.btn-default {
  color: #73879c;
  /* line-height: 18px; */
}

.form-control {
  color: #73879c;
}

hr.image-separator {
  margin-top: 5px;
  border: 1px solid #73879c;
}

.button-row {
  margin-right: 10px;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  top: 3px;
  margin-bottom: 10px;
}

.modal-body-select {
  padding-top: 0px;
  padding-bottom: 0px;
}

.tableColWidthIcon {
  width: 35px;
}

.tableColWidthS {
  width: 80px;
}

.tableColWidthM {
  width: 120px;
}

.tableColWidthL {
  width: 200px;
}

table thead * {
  line-height: 18px;
}

.table-header-col {
  background-color: transparent;
  cursor: pointer;
}

.dropdown-menu {
  line-height: 18px;
  font-size: 12px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: darkgray;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  pointer-events: all;
}

#tasks-select-category {
  width: 200px;
}

#icon-navigation {
  vertical-align: top;
}

/*
.btn-navigation {
  padding: 0px 5px;
  background-color: white;
  margin-right: 5px;
}
*/

.text-navigation {
  margin-right: 5px;
}

.btn-action {
  /* background-color: lightgray;*/
  /* border-color: darkgray; */
  /*color: #212529;*/
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 0px;
}

th {
  vertical-align: middle;
}

td {
  vertical-align: middle;
}

.table {
  margin-bottom: 10px;
}

/*
.table-header-col-no-sort {
  color: #fbfbfb;
  background-color: transparent;
}
*/

.table-header-col:hover {
  text-decoration: underline;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fbfbfb !important;
}

.form-control[disabled] {
  cursor: default;
  background-color: #fbfbfb;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.no-move {
  inset: 0px;
}

.container-columns {
  display: flex;
  flex-wrap: wrap;
}

.container-column-left {
  flex: 1;
  /* height: 100%; */
  padding-right: 5px;
}

.container-column-right {
  flex: 1;
  /* height: 100%; */
  padding-left: 5px;
}

textarea {
  resize: none;
}

/* Style the tab */
div.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
div.tab button {
  color: black;
  /* background-color: inherit; */
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  /* font-size: 17px; */
}

/* Change background color of buttons on hover */
/*div.tab button:hover {
    background-color: #ddd;
}*/

.background-active {
  background-color: #ccc;
}

/* Create an active/current tablink class */
div.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

/*
textarea{
    color:black !important;
	//font-weight: bold !important;
}

input[type="text"]{
	color:black !important;
	//font-weight: bold !important;
}
*/

/*
.shadowCanvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 360px;
  height: 150px;
  background-color: rgba(255, 255, 255, 1.0);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;
}
*/

/* edit size of signature pad here */
#signatureWrapper {
  position: relative;
  left: 0;
  top: 0;
  /* width: 360px; */
  /* height: 150px; */
  width: 720px;
  height: 300px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

/* edit size of signature pad here */
#canvas-signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 360px; */
  /* height: 150px; */
  width: 720px;
  height: 300px;
  border: 1px solid #000000;
}

/* edit size of signature pad here */
#img-signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 360px; */
  /* height: 150px; */
  width: 720px;
  height: 300px;
}

/* edit size of signature on print here */
#signatureImage-print {
  width: 360px;
  height: 150px;
}

.buttonsSignaturePad {
  position: relative;
  top: 5px;
}

.textDecorationLineThrough {
  text-decoration: line-through;
}

.textDecorationNone {
  text-decoration: none;
}

.cursorDefault {
  cursor: default;
}

.fixed {
  position: fixed;
  width: 25px;
}

.debug {
  display: none;
}

.alignleft {
  float: left;
}
.alignright {
  float: right;
}

/*
.moveup10px {
  margin-top: -10px;
}

.moveup30px {
  margin-top: -30px;
}
*/

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.icon {
  width: 24px;
  height: 24px;
}

.material-icons {
  vertical-align: middle;
}

i.mdi {
  font-size: 23px;
  vertical-align: middle;
}

.nav > li > a {
  padding: 6px 15px 6px;
}

.firstColumn {
  white-space: nowrap;
  text-align: left;
}

.noWrap {
  white-space: nowrap;
}

.uib-time input {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.firstColumnDateTimePicker {
  white-space: nowrap;
  margin-top: 30px;
  text-align: left;
}

.div-date-time-picker {
  margin-top: 31px;
}

.pos-9px-up {
  top: -9px;
}

.paddingDatepicker {
  margin-top: 9px;
}

.paddingTimepicker {
  margin-top: -8px;
}

.paddingDateTask {
  margin-top: -10px;
}

.paddingTimeTask {
  margin-top: -29px;
}

.display-flex {
  display: flex;
}

.paddingButtonTimeTask {
  margin-top: 29px;
  margin-left: 10px;
}

.paddingDateButtonTask {
  margin-top: -10px;
}

.word-break-150px {
  max-width: 150px;
  word-wrap: break-word;
}

.word-break-250px {
  max-width: 250px;
  word-wrap: break-word;
}

.inputDatePicker {
  margin-top: 21px !important;
}

.firstColumnSale {
  white-space: nowrap;
  margin-top: 24px;
  text-align: left;
}

/* target small screens (mobile devices or small desktop windows) */
@media only screen and (max-width: 480px) {
  /* CSS goes here */
}

/* high resolution screens */
/*
@media (-webkit-min-device-pixel-ratio: 2),
  (min--moz-device-pixel-ratio: 2),
  (min-resolution: 300dpi) {
  header {
    background-image: url(header-highres.png);
  }
}
*/

/* low resolution screens */
/*
@media (-webkit-max-device-pixel-ratio: 1.5),
  (max--moz-device-pixel-ratio: 1.5),
  (max-resolution: 299dpi) {
  header {
    background-image: url(header-lowres.png);
  }
}
*/

.article-list .col-fixed-1 {
  width: 8em;
}

.col-fixed-2 {
  min-width: 20px;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: white;
}

table thead * {
  background-color: white;
}

.table-row-highlight {
  background-color: lightgray !important;
}

.multiple-select {
  height: 100% !important;
  background: #fbfbfb !important;
}

.x_title > .input-group {
  margin-bottom: 5px;
}

.x_title > .input-group > .input-group-btn {
  vertical-align: top;
}

.x_title button {
  line-height: 19px;
}

/* Elegant Aero */
h1.elegant-aero {
  background: #6d6e70;
  padding: 20px;
  /* padding-top: 55px; */
  margin-bottom: 0;
  color: #fbfbfb;
}

.span-icon-header {
  position: relative;
  cursor: pointer;
}

.icon-header {
  position: absolute;
  left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.print-page-header {
  margin-bottom: 10px;
}

.print-page-footer {
  margin-bottom: 0px;
}

.print-page-body {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media print {
  .print-page-header {
    display: none;
  }

  .print-page-footer {
    display: none;
  }
}

.elegant-aero {
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* max-width: 1000px; */
  background: #b0b2b4;
  padding-top: 10px;
  padding-bottom: 2px;
  font: 12px Arial, Helvetica, sans-serif;
  color: #5e5e5e;
  /* color: #FBFBFB; */
}
/*
.elegant-aero h1 {
  font: 12px "Trebuchet MS", Arial, Helvetica, sans-serif;
  padding: 10px 10px 10px 20px;
  display: block;
  background: #c0e1ff;
  border-bottom: 1px solid #b8ddff;
  margin: -20px -20px 15px;
}
.elegant-aero h1 > span {
  display: block;
  font-size: 11px;
}
*/

.elegant-aero label > span {
  float: left;
  margin-top: 10px;
  color: #5e5e5e;
}
.elegant-aero label {
  display: block;
  margin: 10px 0px 5px;
}
.elegant-aero label > span {
  float: left;
  width: 20%;
  text-align: right;
  padding-right: 15px;
  margin-top: 10px;
  font-weight: bold;
}

.elegant-aero input[type="text"]:not(.input-group-input),
.elegant-aero input[type="number"]:not(.input-group-input),
.elegant-aero input[type="password"]:not(.input-group-input) {
  width: 100%;
  color: #6d6e70;
  padding: 0px 0px 0px 5px;
  border: none;
  background: #fbfbfb;
  outline: 0;
  -webkit-box-shadow: inset 0px 1px 6px #ecf3f5;
  box-shadow: inset 0px 1px 6px #ecf3f5;
  font: 200 12px/25px Arial, Helvetica, sans-serif;
  height: 30px;
  line-height: 15px;
  border-radius: 0px;
  /* margin: 2px 6px 16px 0px; */
  width: 100%;
}

.elegant-aero input[type="text"],
.elegant-aero input[type="password"],
.elegant-aero input[type="email"],
.elegant-aero input[type="number"],
.elegant-aero textarea,
.elegant-aero select {
  color: #6d6e70;
  padding: 0px 0px 0px 5px;
  border: none;
  background: #fbfbfb;
  outline: 0;
  -webkit-box-shadow: inset 0px 1px 6px #ecf3f5;
  box-shadow: inset 0px 1px 6px #ecf3f5;
  font: 200 12px/25px Arial, Helvetica, sans-serif;
  height: 30px;
  line-height: 15px;
  border-radius: 0px;
  /* margin: 2px 6px 16px 0px; */
}
.elegant-aero textarea {
  height: 100px;
  padding: 5px 0px 0px 5px;
  width: 100%;
  resize: none;
}
.elegant-aero select {
  /*
    background: #fbfbfb url('Icons/Navigation/arrow_down.png') no-repeat right;
    background: #fbfbfb url('Icons/Navigation/arrow_down.png') no-repeat right;
    */

  /* works
    background: #fbfbfb url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></g></svg>") no-repeat right;
    */

  background: #fbfbfb
    url("data:image/svg+xml;utf8,<svg fill='%235e5e5e' viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'/></g></svg>")
    no-repeat right;

  /*
    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
    */

  background-size: 32px 32px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  /* width: 70%; */
  width: 100%;
}
.elegant-aero .button {
  padding: 10px 30px 10px 30px;
  background: #66c1e4;
  border: none;
  color: #fff;
  box-shadow: 1px 1px 1px #4c6e91;
  -webkit-box-shadow: 1px 1px 1px #4c6e91;
  -moz-box-shadow: 1px 1px 1px #4c6e91;
  text-shadow: 1px 1px 1px #5079a3;
}
.elegant-aero .button:hover {
  background: #3eb1dd;
}

.elegant-aero .btn-button {
  color: #6d6e70;
  line-height: 28px;
  border: black solid 1px;
}

.elegant-aero .search-btn span {
  margin-right: 5px;
}

.post-content h3 {
  font-size: 12px;
  padding: 20px;
  line-height: 12px;
  margin: 0;
  background: #6d6e70;
}

.btn-link {
  color: #5e5e5e;
}

.btn-link:hover {
  color: #5e5e5e;
}

.icon-datepicker {
  padding: 0px 10px;
  height: 32px;
  color: #5e5e5e;
  position: relative;
  top: -13px;
  right: 14px;
}

.btn-datepicker {
  top: -3px;
  height: 15px;
  width: 12px;
  color: white;
  background-color: white;
  border: none;
}

.btn-datepicker:hover {
  background-color: white;
}

.btn-datepicker:focus {
  background-color: white;
}

.btn-datepicker:active {
  background-color: white;
}

.btn-datepicker:active:focus {
  background-color: white;
}

.input-date-time-date-picker {
  margin-top: 0px;
}

.button-date-time-picker {
  color: white;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.move-down {
  top: 5px;
}

.move-down-button-row {
  position: relative;
  top: -1px;
}

.input-group {
  margin-bottom: 0px;
}

.input-group-text {
  background-color: #fbfbfb !important;
  border: none !important;
  border-radius: 0 !important;
}

/*
.btn-datepicker {
  padding: 0px !important;
  height: 30px;
  margin-top: 1px;
  border: none;
  background: #fbfbfb;
  border-radius: 0px;
}
*/

.btn-datetimepicker {
  padding: 0px !important;
  height: 30px;
  margin-top: 1px;
  border: none;
  top: 10px !important;
  background: #fbfbfb;
  border-radius: 0px;
}

.color-main {
  color: #5e5e5e;
}

.color-bright {
  color: #e7e7e7;
}

.btn-button span {
  margin-right: 5px;
}

.navbar-text {
  margin-bottom: 0px;
}

.no-padding {
  padding: 0px !important;
}

.indicator-green {
  color: white;
  background-color: #4caf50;
}

.indicator-color-green {
  color: #4caf50;
}

.indicator-red {
  color: white;
  background-color: #f44336;
}

.online-indicator {
  width: 20px;
  height: 20px;
  top: 7px;
  border-radius: 50%;
  position: relative;
}

.modal-footer {
  justify-content: flex-start;
}
